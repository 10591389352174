[data-theme='bwLight'] {
  --nav-color: #333;
  --font-color: #333;
  --skill-color: #333;
  --background-color: rgb(255, 255, 255);
  --carousel-background1: rgba(255, 255, 255, 0.7);
  --carousel-background2: rgba(247, 246, 246, 0.7);
  --carousel-background3: rgba(231, 234, 235, 0.7);
  --slider-color: rgb(219, 219, 219);
  --focus-color: rgb(163, 163, 163);
  --font-weight: 300;
  --scrollbar-foreground: rgb(202, 202, 202);
  --scrollbar-background: rgb(255, 255, 255);
}

[data-theme='bwDark'] {
  --nav-color: #333;
  --font-color: #eee;
  --skill-color: #eee;
  --font-weight: 100;
  --background-color: #333;
  --carousel-background1: rgba(119, 119, 119, 0.7);
  --carousel-background2: rgba(112, 112, 112, 0.7);
  --carousel-background3: rgba(59, 59, 59, 0.7);
  --slider-color: rgb(199, 199, 199);
  --filter: invert(100);
  --filter2: invert(100);
  --filter3: white;
  --focus-color: rgb(135, 133, 133);
  --scrollbar-foreground: rgb(112, 112, 112);
  --scrollbar-background: rgb(51, 51, 51);
}

[data-theme='light'] {
  --nav-color:#0093e9;
  --font-color: white;
  --font-weight: 100;
  --background-color: #0093e9;
  --background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
  --carousel-background1: rgba(29, 92, 133, 0.7);
  --carousel-background2: rgba(129, 92, 133, 0.7);
  --carousel-background3: rgba(292, 92, 133, 0.7);
  --slider-color: rgb(199, 199, 199);
  --filter: invert(45%) sepia(55%) saturate(1287%) hue-rotate(147deg)
    brightness(65%) contrast(545%);
  --filter2: invert(45%) sepia(55%) saturate(1287%) hue-rotate(147deg)
    brightness(69%) contrast(545%);
  --filter3: invert(51%) sepia(58%) saturate(1287%) hue-rotate(147deg)
    brightness(65%) contrast(545%);
  --skill-color: #006db2;
  --focus-color: rgb(0, 56, 139);
  --scrollbar-foreground: #f89f92;
  --scrollbar-background: #be837a;
}

[data-theme='dark'] {
  --nav-color:#06466a;
  --font-color: #8ac19f;
  --skill-color: #8ac19f;
  --font-weight: 300;
  --background-color: #003757;
  --background-image: linear-gradient(160deg, #04456a 0%, #48746e 100%);
  --carousel-background1: rgba(18, 54, 77, 0.7);
  --carousel-background2: rgba(70, 30, 74, 0.7);
  --carousel-background3: rgba(153, 56, 82, 0.7);
  --slider-color: rgb(199, 199, 199);
  --filter: invert(68%) brightness(85%) sepia(100%) hue-rotate(90deg);
  --filter2: invert(45%) brightness(155%) sepia(100%) hue-rotate(90deg);
  --filter3: grayscale(100%) sepia(100%) hue-rotate(90deg);
  --focus-color: rgb(197, 197, 197);
  --scrollbar-foreground: #ae7269;
  --scrollbar-background: #6d4c47;
}

[data-theme='light'] .slider {
  background-color:#333;
  toggle-theme-wrapper {
    background-color: #333;
  }
}

[data-theme='dark'] .slider {
  background-color: #444;
}

[data-theme='bwLight'] .slider {
  background-color: #999;
}

[data-theme='bwDark'] .slider {
  background-color: #333;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

html {
  scroll-behavior: smooth;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
}

.navbar {
  position: sticky;
  top: 0;
  background-color: var(--nav-color);
  padding: 10px 0;
  z-index: 1000;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.navbar li {
  margin: 0 15px;
}

.navbar a {
  color: white;
  text-decoration: none;
}

main {
  flex: 1;
  overflow-y: auto;
}

section {
  padding: 0px 20px;
  border-bottom: 1px solid #ccc;
  min-height: 100vh; /* Ensure each section takes up full viewport height */
}

section h2 {
  margin-top: 0;
}

a {
  color: var(--link-color);
}

/*dark / light mode element*/

.toggle-theme-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* gap: 4px; */
  position: fixed;
  justify-content: center;
  width: 100%;
  bottom: 0px;
  background-color: var(--nav-color);
  /* background-image: var(--background-image); */
  /* transition: 0.3s; */
  /* opacity: 0.8; */
  z-index: 25;
}

.toggle-theme-wrapper span {
  /* font-size: 25px; */
  /* color: var(--focus-color); */
}

/* .toggle-theme {
  position: relative;
  display: inline-block;
  height: 36px;
  width: 28px;
} */

.footer-info {
  position: fixed;
  padding-left: 15px;
  text-align: left;
  /* bottom: -10px; */
  left: 0;
  /* width: 250px; */
  color: var(--focus-color);
}

.footer-info a {
  padding-left: 6px;
}

.footer-info p {
  /* font-size: smaller; */
}

.footer-info svg {
  font-size: smaller;
}

.switch {
  position: relative;
  display: inline-block;
  width: 59px;
  height: 28px;
  transition: 0.4s;
  margin-right: 10px;
  margin-bottom: 3PX;
  margin-top: 3px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
  /* z-index: inherit; */
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 3px;
  bottom: 0;
  background-color: var(--background-color);
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 29px;
  width: 29px;
  border-radius: 50%;
  left: 0px;
  bottom: 0px;
  background-color: var(--slider-color);
  transition: 0.4s;
  z-index: 26;
}

.sun svg {
  position: absolute;
  top: 3px;
  left: 32px;
  z-index: 1;
  width: 22px;
  height: 22px;
}

.moon svg {
  fill: #abbecd;;
  position: absolute;
  top: 3.5px;
  left: 2px;
  z-index: 1;
  width: 24px;
  height: 22px;
}

.blackNwhite svg {
  fill: #abbecd;
  position: absolute;
  top: 3px;
  left: 1px;
  z-index: 1;
  width: 24px;
  height: 22px;
}

.palette {
  position: absolute;
  top: 2px;
  left: 15.5px;
  z-index: 1;
  width: 21px;
  height: 21px;
}

/* .switch:hover */
.sun svg {
  animation: rotate 15s linear infinite;
}
.palette img {
  animation: rotate 15s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* .switch:hover */
.moon svg {
  animation: tilt 5s linear infinite;
}

@keyframes tilt {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-20deg);
  }

  75% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.input:checked + .slider {
  background-color: #183153;
}

.input:focus + .slider {
  box-shadow: 0 0 1px #183153;
}

.input:checked + .slider:before {
  transform: translateX(26px);
}
/* rest of app css */

body {
  background-color: var(--background-color);
  background-image: var(--background-image);
  color: var(--font-color);
  font-family: 'Lexend', sans-serif;
  font-weight: var(--font-weight);
  font-size: 1.1em;
  transition: 0.4s;
}

.mobileHeaders {
  display: none;
}

.slider-card {
  width: 700px;
}

.card {
  display: flex;
  padding: 10px;
  white-space: pre-wrap;
  height: auto;
}

.card:nth-child(odd) {
  flex-direction: row;
  margin: 10px 20px 50px 20px;
  background: -moz-linear-gradient(
    270deg,
    var(--carousel-background1) 0%,
    var(--carousel-background2) 52%,
    var(--carousel-background3) 100%
  );

  background: -webkit-linear-gradient(
    270deg,
    var(--carousel-background1) 0%,
    var(--carousel-background2) 52%,
    var(--carousel-background3) 100%
  );

  background: linear-gradient(
    270deg,
    var(--carousel-background1) 0%,
    var(--carousel-background2) 52%,
    var(--carousel-background3) 100%
  );

  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#e6eced",GradientType=1);
  border-radius: 5px;
  text-align: right;
  transition: 0.3s;
}

.card:nth-child(even) {
  flex-direction: row-reverse;
  margin: 10px 20px 50px 20px;
  background: -moz-linear-gradient(
    90deg,
    var(--carousel-background1) 0%,
    var(--carousel-background2) 52%,
    var(--carousel-background3) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    var(--carousel-background1) 0%,
    var(--carousel-background2) 52%,
    var(--carousel-background3) 100%
  );

  background: linear-gradient(
    90deg,
    var(--carousel-background1) 0%,
    var(--carousel-background2) 52%,
    var(--carousel-background3) 100%
  );

  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#e6eced",GradientType=1);
  border-radius: 5px;
  transition: 0.4s;
}

img.card-image {
  box-shadow: 2px 2px;
  border-radius: 5px;
  width: 300px;
  height: auto;
  margin: 8px;
}

.card-info {
  min-width: 300px;
  margin: 10px;
  height: auto;
}

.card-info::first-line {
  font-weight: bold;
}

.card-info a {
  font-weight: 500;
  text-decoration: none;
}

.work-container {
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.attentionGraber {
  display: none;
}

/* nav bar buttons */
.button {
  margin: 1%;
  outline: none;
}

.custom-btn {
  text-align: center;
  width: 90px;
  height: 20px;
  font-family: 'Lexend', sans-serif;
  font-weight: 400;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-flex;
  margin-top: 5px;
}

.btn-3 {
  line-height: 39px;
  padding: 0;
}

.btn-3 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.btn-3:before,
.btn-3:after {
  /* position: absolute; */
  content: '';
  left: 1px;
  top: 1px;
  background: var(--font-color);
  transition: all 0.3s ease;
}

.btn-3:before {
  height: 0%;
  width: 4px;
}

.btn-3:after {
  width: 0%;
  height: 4px;
}

.btn-3:hover:before {
  height: 98%;
}

.btn-3:hover:after {
  width: 98%;
}

.btn-3 span:before,
.btn-3 span:after {
  /* position: absolute; */
  content: '';
  right: 0;
  bottom: 0;
  background: transparent;
  transition: all 0.3s ease;
}

.btn-3 span:before {
  width: 4px;
  height: 0%;
}

.btn-3 span:after {
  width: 0%;
  height: 4px;
}

.btn-3 span:hover:before {
  height: 98%;
}

.btn-3 span:hover:after {
  width: 98%;
}

/* Scroll bar */
.styled-scrollbars {
  --scrollbar-foreground: rgb(172, 98, 98);
  --scrollbar-background: rgb(8, 8, 8);
  /* Foreground, Background */
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
}
::-webkit-scrollbar {
  width: 10px; /* Mostly for vertical scrollbars */
  height: 10px; /* Mostly for horizontal scrollbars */
}
::-webkit-scrollbar-thumb {
  /* Foreground */
  background: var(--scrollbar-foreground);
  border-radius: 12px;
  transition: 0.4s;
}
::-webkit-scrollbar-track {
  /* Background */
  background: var(--scrollbar-background);
  border-radius: 12px;
  margin-bottom: 45px;
  transition: 0.4s;
}

/*  pages */

.landing {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* width: 100vw; */
  text-align: center;
  background-image: var(--background-image);
  transition: 0.4s;
}

.landing img {
  max-width: 90%;
  height: auto;
  filter: var(--filter);
  transition: 0.5s;
}

.aboutContainer {
  height: 100vh;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
}

.about-info {
  display: flex;
  flex-direction: row;
}

.aboutMeInfo {
  width: 350px;
  margin-left: 15px;
}

.aboutMeInfo p {
  margin-top: 6px;
}

.meetJan img {
  width: 350px;
  margin-top: 10px;
  border-radius: 8px;
}

#skills-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.skill span {
  margin-top: 8px;
  font-size: 14px;
}

#gallery-container {
  width: 80vw;
  max-width: 680px;
  /* height: 70vh; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 3vw;
  color: var(--skill-color);
  transition: 0.4s;
}

#contact-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

#contact-logo-container {
  padding: 10px;
  display: flex;
}

#contact-logo-container img {
  width: 100px;
}

.contact {
  margin: 0 25px 0 25px;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  filter: var(--filter3);
  transition: 0.4s;
}

@media screen and (max-width: 730px) {
  .aboutContainer {
    flex-direction: column;
  }

  .about-info {
    flex-direction: column;
  }

  .aboutMeInfo {
    width: 310px;
    margin-left: 5px;
  }

  .meetJan img {
    width: 100%;
    margin-top: 10px;
    border-radius: 8px;
  }

  .slider-card {
    width: 365px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
  }

  .card:nth-child(odd) {
    flex-direction: column;
    background: -moz-linear-gradient(
      270deg,
      var(--carousel-background1) 0%,
      var(--carousel-background2) 52%,
      var(--carousel-background3) 100%
    );

    background: -webkit-linear-gradient(
      270deg,
      var(--carousel-background1) 0%,
      var(--carousel-background2) 52%,
      var(--carousel-background3) 100%
    );

    background: linear-gradient(
      270deg,
      var(--carousel-background1) 0%,
      var(--carousel-background2) 52%,
      var(--carousel-background3) 100%
    );

    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#e6eced",GradientType=1);
    border-radius: 5px;
    text-align: right;
  }

  .card:nth-child(even) {
    flex-direction: column;
    background: -moz-linear-gradient(
      90deg,
      var(--carousel-background1) 0%,
      var(--carousel-background2) 52%,
      var(--carousel-background3) 100%
    );

    background: -webkit-linear-gradient(
      90deg,
      var(--carousel-background1) 0%,
      var(--carousel-background2) 52%,
      var(--carousel-background3) 100%
    );

    background: linear-gradient(
      90deg,
      var(--carousel-background1) 0%,
      var(--carousel-background2) 52%,
      var(--carousel-background3) 100%
    );

    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#e6eced",GradientType=1);
    border-radius: 5px;
  }

  .card {
    padding: 5px;
    height: auto;
  }

  .card-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-image img {
    display: block;
    max-width: 300px;
    max-height: 300px;
    width: auto;
    height: auto;
  }

  #passion-intro {
    margin: -28px;
    margin-bottom: 10px;
    text-align: justify;
  }
}

@media screen and (max-width: 680px) {
  .passion-intro {
    width: 60%;
    font: bolder;
  }

  #gallery-container {
    width: 70%;
  }

  #gallery-container img {
    max-height: 60px;
  }
}

@media screen and (max-width: 580px) {
  #contact-logo-container {
    /* left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  #contact-logo-container img {
    width: 100px;
  }

  .contact {
    margin: 10px 0 10px 0;
  }

  ::-webkit-scrollbar {
    width: 5px; /* Mostly for vertical scroll bars */
    height: 5px; /* Mostly for horizontal scroll bars */
  }
}

/* Hamburger Menu and Nav Bar */

#nav-bar {
  display: flex;
  flex-direction: column;
  z-index: 20;
}

.nav-bar {
  position: fixed;
  width: 100%;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5% 1% 0.5% 1%;
  z-index: 20;
  background-color: var(--background-color);
  opacity: 0.9;
  visibility: visible;
}

.nav-bar a {
  padding: 0.5% 1% 0.5% 1%;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
}

.nav-bar a:hover {
  color: rgb(209, 209, 209);
}

.nav-bar a:focus {
  color: var(--focus-color);
}

/* sidebar Nav */

.hamburger-button {
  margin-top: 10px;
  color: var(--font-color);
  transition: 250ms;
}

.menuNav a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: var(--font-color);
  text-transform: uppercase;
  font-weight: bold;
}

.navBar {
  display: none;
  position: relative;
  z-index: 20;
}

.navBar button {
  position: fixed;
  top: 8px;
  z-index: 10;
  background: transparent;
  border: none;
}

.menuNav {
  display: none;
  list-style: none;
  position: fixed;
  top: 0;
  background: var(--background-color);
  opacity: 0.8;
  left: -44px;
  bottom: 0;
  height: 295px;
  width: 0;
  overflow: hidden;
  max-width: 140px;
  z-index: 9;
  border-radius: 12px;
  box-shadow: 1.5px 1.5px 3px;
  transition: width 250ms;
}

.menuNav.showMenu {
  width: 100%;
  transition: width 250ms;
}

.menuNav li:first-child {
  margin-top: 2.4rem;
}

@media screen and (max-width: 750px) {
  /* .mobileHeaders {
    display: block;
    text-align: left;
    width: 100%;
  } */

  /* .work-container .mobileHeaders {
    margin: 80px 0 15px 75px;
    margin-left: 95px;
  } */

  /* #skills-container .mobileHeaders {
    margin: 130px 0 0 75px;
  } */

  #nav-bar {
    display: none;
  }
  #hamburger {
    display: inline;
  }
  .menuNav {
    display: inline;
  }
  .navBar {
    display: inline;
  }

  .toggle-theme-wrapper {
    justify-content: right;
    padding-right: 15px;
    padding-top: 3px;
    right: 0px;
    background-color: var(--background-color);
    opacity: 0.8;
    z-index: 10;
    transition: 0.4s;
  }
}

@media (pointer: coarse) {
  .attentionGraber {
    opacity: 0;
    z-index: 25;
    /* position: absolute; */
    display: flex;
    bottom: 30px;
    left: 40px;
    animation-name: swipeIcon;
    animation-duration: 2s;
    animation-delay: 0.5s;
  }
}

@keyframes swipeIcon {
  0% {
    opacity: 0;
    left: 20px;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    left: 40px;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 20px;
  }
}
